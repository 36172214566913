<template>
  <v-theme-provider :dark="dark">
    <div class="d-flex flex-column justify-center align-center">
      <base-section-heading
        title="Aderisci al progetto"
        :space="0"
      />
      <div ref="contactForm" />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },
    mounted () {
      window.form.width = undefined
      this.$refs.contactForm.appendChild(window.form)
      window.form.style.position = 'relative'
      window.form.style.opacity = 1
    },
  }
</script>
<i18n>
{
	"en": {
		"info": "Request informations",
		"subtitle": "We put the safety of bathers first, allowing them to enjoy the beach in complete tranquility.",
		"title": "YOUR HOLIDAY IN TOTAL SAFETY",
    "join": "Join the project"
  },
	"it": {
		"info": "Richiedi Informazioni",
		"subtitle": "Poniamo la sicurezza dei bagnanti al primo posto, permettendogli di godersi la spiaggia in piena tranquillità.",
		"title": "VACANZE IN TOTALE SICUREZZA",
    "join": "Aderisci al progetto"
	}
}
</i18n>
