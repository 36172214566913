import Vue from 'vue'
import VueGtag from 'vue-gtag'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import i18n from './i18n'
import filters from './filters'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: {
    id: 'UA-170823156-1',
  },
}, router)

Object.keys(filters).forEach((filter) => {
  Vue.filter(filter, filters[filter])
})

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
