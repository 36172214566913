<template>
  <div
    :class="classes"
    class="base-section-heading"
  >
    <base-avatar
      v-if="icon"
      :icon="icon"
      :outlined="outlined"
      class="mb-4"
      color="primary"
      :size="iconSize"
      dark
    />

    <base-subtitle
      v-if="subtitle"
      :title="subtitle"
      space="1"
      tag="h2"
    />

    <base-subheading
      v-if="title"
      :align="align"
      :title="title"
      class="text-uppercase"
      space="2"
    />

    <base-divider :color="color" />

    <base-body
      v-if="$slots.default || text"
      class="mx-auto"
      max-width="700"
      :dark="dark"
    >
      <slot v-if="$slots.default" />

      <template v-else>
        <div v-html="text" />
      </template>
    </base-body>
  </div>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'BaseSectionHeading',

    mixins: [Heading],

    props: {
      dark: {
        required: false,
        default: false,
      },
      align: {
        type: String,
        default: 'center',
      },
      color: {
        type: String,
        default: 'primary',
      },
      icon: String,
      iconSize: Number,
      outlined: Boolean,
      // margin inserito perchè da mobile troppo vicino al bordo
      margin: {
        type: [Number, String],
        default: 2,
      },
      space: {
        type: [Number, String],
        default: 8,
      },
      subtitle: String,
      text: String,
      title: String,
    },

    computed: {
      classes () {
        return [
          `text-${this.align}`,
          `py-${this.space}`,
          `ml-${this.margin}`, // margini
          `mr-${this.margin}`,
        ]
      },
    },
  }
</script>
