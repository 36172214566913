<template>
  <v-banner
    v-if="!cookie"
    id="cookie-policy"
    elevation="6"
  >
    {{ $t('title') }}
    <template v-slot:actions>
      <v-btn
        text
        color="primary"
        @click="setCookie()"
      >
        OK
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="privacy()"
      >
        Privacy Policy
      </v-btn>
    </template>
  </v-banner>
</template>
<script>
  export default {
    data () {
      return {
        cookie: localStorage.cookie,
      }
    },
    created () {
      this.checkCookies()
    },
    methods: {
      checkCookies () {
        let value = true
        if (localStorage.cookie === undefined) {
          value = false
        }
        localStorage.cookie = value
        return localStorage.cookies
      },
      setCookie () {
        localStorage.cookie = this.cookie = true
      },
      privacy () {
        this.setCookie()
        this.$router.push('/public/privacy-policy.html')
      },
      join () {

      },
    },
  }
</script>
<style>
  #cookie-policy {
    position: fixed !important;
    bottom: 0px !important;
    z-index: 2 ;
    background-color: white;
    min-width: 100vw;
  }
</style>
<i18n>
{
	"en": {
		"title": "This site uses third party analytical cookies. Click below to read the privacy policy."
	},
	"it": {
		"title": "Questo sito usa cookie analitici di terze parti. Clicca in basso per leggere l’informativa privacy."
	}
}
</i18n>
