<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require(`@/assets/imgs/mnn/logoWhite.png`)"
        color="grey lighten-1"
        max-width="172"
        tile
      />

      <base-title
        size="body-1"
        space="4"
        title=" "
        weight="regular"
      />
      <div class="CANCELED" />
      <!--
      <base-btn
        class="mb-12"
        color="white"
        outlined
        @click="openPage"
      >
        {{ $i18n.t('more') }}
      </base-btn>
      -->
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },
    // *************
    // ** DATA    **
    // *************
    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '01 (800) 433 744<br>01 (800) 433 633',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'john@vuetifyjs.com<br>heather@vuetifyjs.com',
        },
      ],
    }),
    // *************
    // ** METHODS **
    // *************
    methods: {
      openPage () {
        window.open('https://minnovi.com/', '_blank')
      },
    },
  }
</script>
<i18n>
{
	"en": {
		"more": "Find out more"
	},
	"it": {
		"more": "Scopri di più"
	}
}
</i18n>
